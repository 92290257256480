<template>
  <div class="password-reset-send-after">
    <div class="page-title">
      <base-page-title>{{
        $t('passwordResetSendAfter.pageTitle')
      }}</base-page-title>
    </div>
    <div class="password-reset-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="message">
      <div class="message-p1" data-test="message-p1">
        {{ $t('passwordResetSendAfter.textSentEmail') }}
      </div>
      <div class="message-p2" data-test="message-p2">
        {{ $t('passwordResetSendAfter.textGoToSettingPage') }}
      </div>
      <div class="message-p3" data-test="message-p3">
        {{ $t('passwordResetSendAfter.textIfNotReceiveEmail') }}
      </div>
    </div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import PreventBack from '@/components/mixins/PreventBack'

export default {
  name: 'PasswordResetSendAfter',

  components: {
    BasePageTitle,
    ProgressBar
  },

  mixins: [PreventBack],

  data() {
    return {
      progressBarData: {
        textList: [
          'common.progressBarEnterEmail',
          'common.progressBarAuthentication',
          'common.progressBarNewPassword',
          'common.progressBarFinish'
        ],
        barWidthPc: 'password-resetting-screen',
        textOrangeNumber: 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset-send-after {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 70px;
  }
  > .password-reset-progress-bar {
    margin-top: 27px;
  }
  > .message {
    margin-top: 38px;
    font-weight: bold;
    @include font-size(18);
    > .message-p2 {
      margin-top: 30px;
      color: #{$plus_red};
    }
    > .message-p3 {
      margin-top: 27px;
    }
  }
}
@media (max-width: $global-media-width) {
  .password-reset-send-after {
    > .message {
      max-width: 410px;
      width: calc-percent(410px, 640px);
      margin: 59px auto 0px;
      text-align: left;
      > .message-p3 {
        margin-top: 43px;
      }
    }
  }
}
</style>
