export default {
  mounted() {
    if (this.$vnode && this.$vnode.data.keepAlive) {
      return
    } else {
      this.preventBack()
    }
  },
  methods: {
    preventBack() {
      let path = this.$route.fullPath
      if (this.$router.mode === 'hash') {
        path = '#' + path
      }
      window.pushStateFunction = () => {
        let state = { title: null, url: path }
        window.history.pushState(state, null, path)
      }
      const state = { title: null, url: path }
      window.history.pushState(state, null, path)
      window.addEventListener('popstate', window.pushStateFunction, false)
    }
  },
  // ↓ <keep-alive>コンポネントの場合に使う
  // activated() {
  //   this.preventBack()
  // },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('popstate', window.pushStateFunction, false)
    next()
  }
}
